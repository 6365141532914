import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from '@/utils/auth'
import { getAllHRDepartments } from "@/api/department";
import { getTemplates } from "@/api/worklog";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: auth.getUser(),
    photo: null,
    username: localStorage.getItem("znkm-h5-username"),
    password: localStorage.getItem("znkm-h5-password"),
    hasSystmeUpdate: false,
    systemMessage: []
  },

  mutations: {
    updateHasSystemUpdate(state, hasSystmeUpdate) {
      state.hasSystmeUpdate = hasSystmeUpdate
    },
    updateSystemMessage(state, systemMessage) {
      state.systemMessage = systemMessage
    },

    //  更新用户头像的方法 载荷 携带参数用的
    updatePhoto(state, payload) {
      state.photo = payload.photo
    },
    updateUser(state, payload) {
      state.user = payload.user // 更新数据
      auth.setUser(payload.user) // 将更新得数据同步到本低存储中
    },
    rememberUser(state, userlogin) {


      state.username = userlogin.username;
      let p = userlogin.password + "$$$" + userlogin.username
      state.password = window.btoa(window.btoa(p));

      localStorage.setItem("znkm-h5-username", state.username)

      localStorage.setItem("znkm-h5-password", state.password)


    },


    // 清空数据
    clearUser(state) {
      state.user = {}
      auth.delUser(state)

      sessionStorage.removeItem("znkm-h5-allDepartments")
      sessionStorage.removeItem("znkm-h5-userWorkLogTemplates")
    }
  },
  getters: {


    gethasSystmeMessage(state) {
      return state.hasSystmeUpdate || state.systemMessage.length > 0;
    },
    gethasSystmeUpdate(state) {
      return state.hasSystmeUpdate;
    },
    getsystemMessage(state) {
      return state.systemMessage;
    },
    getMessage(state) {
      if (state.systemMessage.length > 0) {
        for (let index = 0; index < state.systemMessage.length; index++) {
          const element = state.systemMessage[index];
          if (element.messageType == 3) {
            return element
          }
        }
      }
      return {}

    },
    getMyComment(state) {
      if (state.systemMessage.length > 0) {
        for (let index = 0; index < state.systemMessage.length; index++) {
          const element = state.systemMessage[index];
          if (element.messageType == 1) {
            return element
          }
        }
      }
      return {}
    },
    getMyLike(state) {
      if (state.systemMessage.length > 0) {
        for (let index = 0; index < state.systemMessage.length; index++) {
          const element = state.systemMessage[index];
          if (element.messageType == 2) {
            return element
          }
        }
      }
      return {}
    },
    getCommentLikeCount(state){
      let count=0;
      if (state.systemMessage.length > 0) {
        for (let index = 0; index < state.systemMessage.length; index++) {
          const element = state.systemMessage[index];
          if (element.messageType == 2 || element.messageType == 1) {
            count=count+element.messageCount;
          }
        }
      }
      if(count>0)
      {
        if(count>999)
        {
          return "999";
        }
        return count;
      }
      else{
        return""
      }
    },
    getMessageTotalCount(state){
      let count=0;
      if (state.systemMessage.length > 0) {
        for (let index = 0; index < state.systemMessage.length; index++) {
          const element = state.systemMessage[index];
            count=count+element.messageCount;
        }
      }
      if(count>0)
      {
        if(count>999)
        {
          return "999";
        }
        return count;
      }
      else{
        return""
      }
    },
    getRememberUser: state => {


      if (state.username) {
        let passstr = window.atob(window.atob(state.password));
        passstr = passstr.substring(0, passstr.indexOf("$$$"))
        let u = { username: state.username, password: passstr }
        return u;
      }
      return null
    },
    async getAllDepartments() {
     var allDepartmentsJSON= sessionStorage.getItem("znkm-h5-allDepartments")
      if (!allDepartmentsJSON) {
        var d = await getAllHRDepartments()
        allDepartmentsJSON = JSON.stringify(d.data.data)
        sessionStorage.setItem("znkm-h5-allDepartments", allDepartmentsJSON)
        return d.data.data
      }
      return JSON.parse(allDepartmentsJSON)
    },
    async getUserWorkLogTemplates() {
      var userWorkLogTemplatesJSON= sessionStorage.getItem("znkm-h5-userWorkLogTemplates")
       if (!userWorkLogTemplatesJSON) {
         var d = await getTemplates()
         userWorkLogTemplatesJSON = JSON.stringify(d.data.data)
         sessionStorage.setItem("znkm-h5-userWorkLogTemplates", userWorkLogTemplatesJSON)
         return d.data.data
       }
        return JSON.parse(userWorkLogTemplatesJSON)
     },
  },
  actions: {
  },
  modules: {
  }
})
