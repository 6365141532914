// 封装request模块
import { Toast } from 'vant';
import axios from 'axios'
import JSONBig from 'json-bigint'
import store from '@/store'
import router from '@/router'
import { sendLogToServer } from "@/api/systemerror";



const instance = axios.create({
   //baseURL: 'http://192.168.10.231:8080',//'http://localhost:8080',
  baseURL: '/',
  transformResponse: [function (data) {
    
     try {
      return JSONBig.parse(data)
     } catch (error) {
       return data
     }
  }]
})

// 请求拦截器注入Token
instance.interceptors.request.use(function (config) {
  // debugger
  if (store.state.user.token) {
    // config.headers['Authorization'] = `Bearer ${store.state.user.token}`
    config.headers.Authorization = `Bearer ${store.state.user.token}`
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

//响应拦截器
instance.interceptors.response.use(function (response) {
  try {
    return response
  } catch (error) {
    return response
  }
}, async function (error) {

  if(error.config.url=="/api/SystemError/Add"){
    return Promise.reject(error)
  }

  else if (error.response && error.response.status === 401) {
    //Toast("登录过期,请重新登录");
    let toPath = { path: '/login', query: { redirectUrl: router.currentRoute.fullPath } }
    router.push(toPath);

    // //   表示token过期 先判断 是否有refresh_token
    // if (store.state.user.refresh_token) {
    //   try {
    //     //   应该发送一个请求 换取新的token
    //   // 这里不应该再用instance  因为 instance会再次进入拦截器  用默认的axios
    //     let result = await axios({
    //       url: 'http://ttapi.research.itcast.cn/app/v1_0/authorizations',
    //       method: 'put',
    //       headers: { Authorization: `Bearer ${store.state.user.refresh_token}` }
    //     })
    //     store.commit('updateUser', {
    //       user: {
    //         token: result.data.data.token, // 拿到新的token之后
    //         refresh_token: store.state.user.refresh_token
    //       }
    //     })
    //     return instance(error.config)
    //   } catch (error) {
    //     //  如果错误 表示补救措施也没用了 应该跳转到登录页 并且 把废掉的user全都干掉
    //     store.commit('clearUser')
    //     router.push(toPath)
    //   }
    // } else {
    //   router.push(toPath)
    // }
  }
 
  else if(error.response && error.response.status>=500){
    Toast("访问服务器异常，请稍后再试！");
    Toast.allowMultiple();//默认单例模式
  }
  else if(error.response && error.response.status==413){
    Toast("上传文件过大,"+error.message);
    Toast.allowMultiple();//默认单例模式
  }
  else{

    const errorInfo={
      typeId:1,
      url:error.config.url,
      requestData: JSON.stringify(error.config.data),
      errorInfo:error.message
    };
    sendLogToServer(errorInfo);
    Toast("请求异常："+error.message);
    Toast.allowMultiple();//默认单例模式
  }
  return Promise.reject(error)
})

export default instance
