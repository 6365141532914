import request from '@/utils/request'

export function getCloudDiskList(params) {
    return request({
        url: '/api/CloudDisk/List',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}

// 获取详情
export function getCloudDiskInfo(id) {
    return request({
        url: `/api/CloudDisk?para=${id}`,
    })
}

export function getPath(id) {
    return request({
        url: `/api/CloudDisk/GetPath?id=${id}`,
    })
}
export function getPreNext(id) {
    return request({
        url: `/api/CloudDisk/GetPreNext?id=${id}`,
    })
}

//下载

export function getCloudDiskDownLoadFile(id) {
    return request({
        url: `/api/CloudDisk/DownLoadFile?id=${id}`,
        responseType: 'blob',
    })
}

export function addCloudDiskDownLoadFileLog(id) {
    return request({
        url: `/api/CloudDisk/DownLoadFileLog?id=${id}`,
    })
}

export function getDownLoadFilefromUrl(url){
    return request({
        url: `/api/CloudDisk/DownloadFromUrl?url=${url}`,
        responseType: 'blob',
    })
}

