const USER_TOKEN = 'znkm-h5'

export function setUser (user) {
  localStorage.setItem(USER_TOKEN, JSON.stringify(user))
}
export function getUser () {
  return JSON.parse(localStorage.getItem(USER_TOKEN) || '{}')
}
export function delUser () {
  localStorage.removeItem(USER_TOKEN)
}
