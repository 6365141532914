import Vue from "vue";
import App from "./App.vue";
import router from "./permission";
import store from "./store";
import Vant, { Lazyload, Popup } from "vant";
import plugin from "@/utils/plugin";
import "vant/lib/index.less";
import "@/styles/index.less";
import "amfe-flexible";
import { Image as VanImage } from "vant";
import { Swipe, SwipeItem } from "vant";
import { Grid, GridItem, Toast, List } from "vant";
import { Loading } from "vant";
import { Col, Row } from "vant";
import { Button, Icon, PullRefresh, Sticky } from "vant";
// 引入遮罩层
import { Overlay } from "vant";
// 引入懒加载
import warterMark from "@/utils/watermark";
import { Dialog } from "vant";

import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
import "@vant/touch-emulator";
import { saveAs } from "file-saver";
import { getDownLoadFilefromUrl } from "@/api/clouddisk";
import axios from "axios";
import "./assets/font_ny3w1fxk9pd/iconfont.css";
import VueLuckyCanvas from '@lucky-canvas/vue'
import { sendLogToServer } from "@/api/systemerror";

Vue.prototype.$axios = axios;

var option = {
  //   maxSpreadZoom: 2, // 控制预览图最大的倍数，默认是2倍，我这里改成了原图
  //  fullscreenEl: false, //控制是否显示右上角全屏按钮
  //   closeEl: false, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库

  // shareEl: false, //控制是否显示分享按钮
  // zoomEl: false, //控制是否显示放大缩小按钮
  // counterEl: false, //控制是否显示左上角图片数量按钮
  // arrowEl: true,  //控制如图的左右箭头（pc浏览器模拟手机时）
  // tapToToggleControls: true, //点击应切换控件的可见性
  // clickToCloseNonZoomable: true //点击图片应关闭图库，仅当图像小于视口的大小时
  dodownloadimageFn: async function(imageurl) {
    let isWenXin =
      navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    let isMobile = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (isWenXin && isMobile) {
      Toast("微信中下载受限，请点击右上角三个点，在浏览器中打开下载源文件");
      return;
    }
    Toast("正在下载...");
    var tempurllist = imageurl.split("/");
    var filename = tempurllist[tempurllist.length - 1];
    imageurl = encodeURIComponent(imageurl);
    var data = await getDownLoadFilefromUrl(imageurl);
    saveAs(
      data.data,
      //"/api/CloudDisk/DownLoadFile?id=" + this.cloudDiskFile.id,
      filename
    );
  },
};
Vue.use(List);
Vue.use(Sticky);
Vue.use(PullRefresh);
Vue.use(preview, option);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(preview);
Vue.use(Button);
Vue.use(Col);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Loading);
Vue.use(Grid);
Vue.use(GridItem);
// 使用遮罩层
Vue.use(Overlay);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(Vant);
Vue.use(plugin);
// Vue.use(Lazyload)

Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.config.productionTip = false;
Vue.use(Dialog);
Vue.use(VueLuckyCanvas)
new Vue({
  router,
  store,
  methods:{
    /**
     * 检查是否有新版本
     */
    checkVersion(){
      axios
        .get(`/json/version.json`, { headers: { "Cache-Control": "no-cache" } }) // 反正就是要请求到json文件的内容, 并且禁止缓存
        .then((res) => {
          const version = res.data.version;
          const clientVersion = localStorage.getItem("_version_");
          // 和最新版本不同，刷新页面
          if (version !== clientVersion) {
            Toast("系统检查到新版本，正在准备刷新");
            localStorage.setItem("_version_", version);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        });
    }
  },
  mounted: function() {
    if (store.state.user.trueName != undefined) {
      let usermolie = "";
      if (store.state.user.mobile.length > 4) {
        usermolie = store.state.user.mobile.substr(
          store.state.user.mobile.length - 4
        );
      }
      let userstr = store.state.user.trueName + usermolie;
      warterMark.set(userstr);
    }
    //检查是否更新
    // this.checkVersion();//加载检查是否更新
    // setInterval(() => {
    //   this.checkVersion();
    // }, 60000); // 1分钟一次

    //console.log(warterMark);
  },

  render: (h) => h(App),
}).$mount("#app");
// 下面的代码 就是   5+ Runtime的处理物理返回按钮的问题
document.addEventListener("plusready", function() {
  var webview = window.plus.webview.currentWebview();
  window.plus.key.addEventListener("backbutton", function() {
    webview.canBack(function(e) {
      if (e.canBack) {
        webview.back();
      } else {
        // webview.close(); //hide,quit
        // plus.runtime.quit();
        // 首页返回键处理
        // 处理逻辑：1秒内，连续两次按返回键，则退出应用；
        var first = null;
        window.plus.key.addEventListener(
          "backbutton",
          function() {
            // 首次按键，提示‘再按一次退出应用’
            if (!first) {
              first = new Date().getTime();
              setTimeout(function() {
                first = null;
              }, 1000);
            } else {
              if (new Date().getTime() - first < 1500) {
                window.plus.runtime.quit();
              }
            }
          },
          false
        );
      }
    });
  });
});

// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();
});

window.onerror = function (message, source, lineno, colno, error) {
  const errorInfo={
    typeId:2,
    url:window.location.href,
    requestData: null,
    errorInfo:error ? error.stack : message,
  };
  //排除
  if(errorInfo.errorInfo=='ResizeObserver loop completed with undelivered notifications.'){
    return;
  }
  sendLogToServer(errorInfo);
};