import request from '@/utils/request'
export function getCenterList (params) {
  return request({
    url: '/api/Department/CenterList',
    data: {  ...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错 
    },
    
  })
}

export function getAllHRDepartments (params) {
  return request({
    url: '/api/Department/GetAllHRDepartmetns',
    params: params,
    method: 'get',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错 
    },
    
  })
}