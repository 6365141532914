import request from '@/utils/request'

export function getTemplate(params) {
  return request({
    url: '/api/WorkLogTemplate',
    params: params,
    method: 'get',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function getTemplates(params) {
  return request({
    url: '/api/WorkLogTemplate/ListByCurrentUser',
    params: params,
    method: 'get',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function add (params) {
    return request({
      url: '/api/WorkLog',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }

  export function shareToBbs (params) {
    return request({
      url: '/api/WorkLog/ShareToBbs',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }

  export function addTemplate (params) {
    return request({
      url: '/api/WorkLogTemplate',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }

  export function getPageList(params) {
    return request({
      url: '/api/WorkLog/Page',
      params: params,
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }

  export function get(params) {
    return request({
      url: '/api/WorkLog',
      params: params,
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }

  export function getStatisticalData(params) {
    return request({
      url: '/api/WorkLog/GetStatisticalData',
      params: params,
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }

  export function getCurrentUserPartitionNewestWorkLogs(params) {
    return request({
      url: '/api/WorkLog/GetCurrentUserPartitionNewestWorkLogs',
      params: params,
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }

  export function getUserStatisticalData(params) {
    return request({
      url: '/api/WorkLog/GetUserStatisticalData',
      params: params,
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }

  export function getTopPermissionCount(params) {
    return request({
      url: '/api/WorkLog/TopPermissionCount',
      params: params,
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }

  export function exportExcel(params) {
    return request({
      url: '/api/WorkLog/Export',
      params: params,
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      responseType: 'blob',
    })
  }