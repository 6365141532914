import router from '@/router'
import store from '@/store'
import { setCookie } from '@/utils/cookie'

router.beforeEach(function (to, from, next) {
  console.log(to.path);

  //登录缓存问题，临时生成grafana_auth，后期要尽快删除
  if (store.state.user && store.state.user.id && store.state.user.code) {
    let b64EncodeUnicode = (str) => {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode('0x' + p1);
        }));
    }
    let grafana_auth = b64EncodeUnicode(store.state.user.code + ':' + store.state.user.id.toUpperCase())
    setCookie('grafanaAuth', grafana_auth, 30);
  }

  if (
    to.path != '/z/list' &&
    to.path != '/z' &&
    to.path != '/user/findpassword' &&
    //to.path.startsWith('/user') 
    to.path.indexOf('/login') == -1
    && !store.state.user.token) {
    let toPath = { path: '/login', query: { redirectUrl: to.fullPath } }
    next(toPath)
  } else {
    next()
  }
})

export default router
